import * as React from "react"
import { useState, useLayoutEffect, useContext, useEffect, useRef } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { Helmet } from "react-helmet"

import "../components/css/video.scss"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import { BsArrowUp } from "react-icons/bs"
import { MdPlayCircle } from "react-icons/md"
import ModalVideo from "react-modal-video"
import {
  EdgeQLauch,
  Talk5G,
  Fundamental5GDetails,
  Demo5G,
} from "../components/ytubeDetails"

import { detect } from "detect-browser"

const Video = ({location}) => {
  const browser = detect()

  const videoTabRightRef = useRef(null); // Add this ref

  const [lauchList, setLauchList] = React.useState([]);
  const [talk5GList, setTalk5GList] = React.useState([]);
  const [demo5GList, setDemo5GList] = React.useState([]);

  const [fundamental5GList, setFundamental5GList] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false)
  const [photoIndex,setPhotoIndex]  = React.useState(0)
  const [mainVideoURL,setMainVideoURL] = React.useState("edgeQLauches")

  React.useLayoutEffect(() => {
    setFundamental5GList([...Fundamental5GDetails])
    setLauchList([...EdgeQLauch])
   setTalk5GList([...Talk5G])
   setDemo5GList([...Demo5G])
  }, [])

  const lightboxOpen = index => {
    setIsOpen(true)
    setPhotoIndex(index)
    document.querySelector("html").style.overflow = "hidden"
    window.fullpage_api.setAllowScrolling(false)
  }

  const closePopup = () => {
    setIsOpen(false)
    document.querySelector("html").style.overflow = "auto"
    window.fullpage_api.setAllowScrolling(true)
  }

  const medialightboxOpen = index => {
    setIsOpen(true)
    setPhotoIndex(index)
    document.querySelector("html").style.overflow = "hidden"
  }
  const mediaclosePopup = () => {
    setIsOpen(false)
    document.querySelector("html").style.overflow = "auto"
  }

    
  const changeVideoURL = (URL)=>{
    setMainVideoURL(URL);
    
    // Scroll to the "video_tab_right" section when a tab is clicked
    if (videoTabRightRef.current) {
      videoTabRightRef.current.scrollIntoView({ behavior: "smooth" });
    }
   }

  return (
    <Layout>
      <Seo title="EdgeQ | Video" />
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Company - EdgeQ" />
        <meta
          property="og:description"
          content="our passion About EdgeQ EdgeQ is a leading innovator creating the industry&#8217;s first 5G Base-Station-on-a-Chip. Led by seasoned executives from Qualcomm, Intel, and Broadcom who have delivered industry-transforming technologies (4G/5G, WiFi, Wimax, Artificial Intelligence, Cloud Servers) for the last few decades, EdgeQ is inventing a new paradigm within the wireless infrastructure industry. Our vision is &hellip; Company Read More &raquo;"
        />
        <meta property="og:url" content="https://edgeq.io/company/" />
        <meta property="og:site_name" content="EdgeQ" />
        <meta
          property="article:modified_time"
          content="2021-03-08T21:44:16+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="4 minutes" />
      </Helmet>

      <section className="company-first-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="company-heading video-heading">
                <h2 data-aos="fade-down">
                  {" "}
                  Pioneering <span className="orange uppercase">EdgeQ</span>
                </h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="video-tab-section">
        <Container>
          <Row>
            <Col md={12}>
              <Tabs>
                {mainVideoURL === "edgeQLauches" &&
                  typeof window !== "undefined" && (
                    <ModalVideo
                      channel="youtube"
                      autoplay="1"
                      isOpen={isOpen}
                      videoId={lauchList[photoIndex]?.videoURL}
                      onClose={
                        location.pathname !== "/" ? mediaclosePopup : closePopup
                      }
                    />
                  )}

                {mainVideoURL === "talk5G" && typeof window !== "undefined" && (
                  <ModalVideo
                    channel="youtube"
                    autoplay="1"
                    isOpen={isOpen}
                    videoId={talk5GList[photoIndex]?.videoURL}
                    onClose={
                      location.pathname !== "/" ? mediaclosePopup : closePopup
                    }
                  />
                )}

                {mainVideoURL === "fundemental5G" &&
                  typeof window !== "undefined" && (
                    <ModalVideo
                      channel="youtube"
                      autoplay="1"
                      isOpen={isOpen}
                      videoId={fundamental5GList[photoIndex]?.videoURL}
                      onClose={
                        location.pathname !== "/" ? mediaclosePopup : closePopup
                      }
                    />
                  )}

                {mainVideoURL === "Demo5G" && typeof window !== "undefined" && (
                  <ModalVideo
                    channel="youtube"
                    autoplay="1"
                    isOpen={isOpen}
                    videoId={demo5GList[photoIndex]?.videoURL}
                    onClose={
                      location.pathname !== "/" ? mediaclosePopup : closePopup
                    }
                  />
                )}
             
                <div className="video_tab_left">
                  <TabList>
                    <Tab  onClick={()=>changeVideoURL("edgeQLauches")}>Pioneering EdgeQ</Tab>
                    <Tab  onClick={()=>changeVideoURL("talk5G")}>5G Industry Talks</Tab>
                    <Tab onClick={()=>changeVideoURL("fundemental5G")}>5G Fundamentals</Tab>
                    <Tab onClick={()=>changeVideoURL("Demo5G")}>5G Demo</Tab>
                  </TabList>
                  <div className="text-center mt-5">
                  <a href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" className="yt_btn"  rel="noreferrer"><span className="arowup"><BsArrowUp/></span>Youtube</a>
                  </div>
                </div>
                

                <div className="video_tab_right" ref={videoTabRightRef}>
                  <TabPanel >
                    <Row>
                      {lauchList.map((tlist, index) => {
                        return (
                          <Col md={6}>
                            <div className="img__Slide__boxs" key={index}>
                              <div
                                className="slideOverlays"
                                onClick={() =>
                                  location.pathname !== "/"
                                    ? medialightboxOpen(index)
                                    : lightboxOpen(index)
                                }
                              >
                                {" "}
                                <svg
                                  width="44"
                                  height="46"
                                  viewBox="0 0 44 46"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M22 0.65625C17.6612 0.65625 13.4198 1.96669 9.81219 4.42185C6.20458 6.87701 3.3928 10.3666 1.7324 14.4494C0.0720052 18.5322 -0.362431 23.0248 0.484033 27.3591C1.3305 31.6933 3.41984 35.6746 6.48786 38.7994C9.55587 41.9243 13.4648 44.0523 17.7202 44.9144C21.9757 45.7766 26.3866 45.3341 30.3951 43.6429C34.4037 41.9518 37.8299 39.0879 40.2404 35.4135C42.6509 31.7391 43.9375 27.4192 43.9375 23C43.9314 17.076 41.6181 11.3964 37.5054 7.20749C33.3926 3.01858 27.8163 0.662506 22 0.65625ZM22 41.9063C18.3287 41.9063 14.7398 40.7974 11.6872 38.72C8.63465 36.6425 6.25545 33.6898 4.8505 30.2351C3.44554 26.7804 3.07794 22.979 3.79418 19.3116C4.51042 15.6441 6.27833 12.2754 8.87434 9.63126C11.4704 6.98718 14.7779 5.18653 18.3786 4.45703C21.9794 3.72753 25.7117 4.10193 29.1036 5.5329C32.4954 6.96387 35.3945 9.38713 37.4342 12.4963C39.4738 15.6054 40.5625 19.2607 40.5625 23C40.5569 28.0125 38.5994 32.8181 35.1195 36.3625C31.6396 39.9068 26.9214 41.9006 22 41.9063ZM29.6866 21.5691L19.5616 14.6941C19.3074 14.5214 19.0119 14.4222 18.7067 14.4071C18.4014 14.3921 18.0979 14.4617 17.8285 14.6086C17.5591 14.7555 17.334 14.9742 17.177 15.2412C17.0201 15.5083 16.9373 15.8138 16.9375 16.125V29.875C16.9373 30.1862 17.0201 30.4917 17.177 30.7588C17.334 31.0258 17.5591 31.2445 17.8285 31.3914C18.0979 31.5383 18.4014 31.6079 18.7067 31.5929C19.0119 31.5778 19.3074 31.4786 19.5616 31.3059L29.6866 24.4309C29.918 24.274 30.1079 24.0612 30.2392 23.8116C30.3705 23.562 30.4392 23.2832 30.4392 23C30.4392 22.7168 30.3705 22.438 30.2392 22.1884C30.1079 21.9388 29.918 21.726 29.6866 21.5691ZM20.3125 26.6631V19.3477L25.7083 23L20.3125 26.6631Z"
                                    fill="white"
                                    fill-opacity="0.58"
                                  />
                                </svg>
                              </div>
                              <img src={tlist.imgURL} alt={`edgeq-${index}`} />
                            </div>
                            <div className="video-slide-content" key={index}>
                              <h3>{tlist.title}</h3>

                              <p>{tlist.details}</p>
                            </div>
                          </Col>
                        )
                      })}
                    </Row>
                  </TabPanel>

                  <TabPanel>
                    <Row>
                      {talk5GList.map((tlist, index) => {
                        return (
                          <Col md={6}>
                            <div className="img__Slide__boxs" key={index}>
                              <div
                                className="slideOverlays"
                                onClick={() =>
                                  location.pathname !== "/"
                                    ? medialightboxOpen(index)
                                    : lightboxOpen(index)
                                }
                              >
                                {" "}
                                <svg
                                  width="44"
                                  height="46"
                                  viewBox="0 0 44 46"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M22 0.65625C17.6612 0.65625 13.4198 1.96669 9.81219 4.42185C6.20458 6.87701 3.3928 10.3666 1.7324 14.4494C0.0720052 18.5322 -0.362431 23.0248 0.484033 27.3591C1.3305 31.6933 3.41984 35.6746 6.48786 38.7994C9.55587 41.9243 13.4648 44.0523 17.7202 44.9144C21.9757 45.7766 26.3866 45.3341 30.3951 43.6429C34.4037 41.9518 37.8299 39.0879 40.2404 35.4135C42.6509 31.7391 43.9375 27.4192 43.9375 23C43.9314 17.076 41.6181 11.3964 37.5054 7.20749C33.3926 3.01858 27.8163 0.662506 22 0.65625ZM22 41.9063C18.3287 41.9063 14.7398 40.7974 11.6872 38.72C8.63465 36.6425 6.25545 33.6898 4.8505 30.2351C3.44554 26.7804 3.07794 22.979 3.79418 19.3116C4.51042 15.6441 6.27833 12.2754 8.87434 9.63126C11.4704 6.98718 14.7779 5.18653 18.3786 4.45703C21.9794 3.72753 25.7117 4.10193 29.1036 5.5329C32.4954 6.96387 35.3945 9.38713 37.4342 12.4963C39.4738 15.6054 40.5625 19.2607 40.5625 23C40.5569 28.0125 38.5994 32.8181 35.1195 36.3625C31.6396 39.9068 26.9214 41.9006 22 41.9063ZM29.6866 21.5691L19.5616 14.6941C19.3074 14.5214 19.0119 14.4222 18.7067 14.4071C18.4014 14.3921 18.0979 14.4617 17.8285 14.6086C17.5591 14.7555 17.334 14.9742 17.177 15.2412C17.0201 15.5083 16.9373 15.8138 16.9375 16.125V29.875C16.9373 30.1862 17.0201 30.4917 17.177 30.7588C17.334 31.0258 17.5591 31.2445 17.8285 31.3914C18.0979 31.5383 18.4014 31.6079 18.7067 31.5929C19.0119 31.5778 19.3074 31.4786 19.5616 31.3059L29.6866 24.4309C29.918 24.274 30.1079 24.0612 30.2392 23.8116C30.3705 23.562 30.4392 23.2832 30.4392 23C30.4392 22.7168 30.3705 22.438 30.2392 22.1884C30.1079 21.9388 29.918 21.726 29.6866 21.5691ZM20.3125 26.6631V19.3477L25.7083 23L20.3125 26.6631Z"
                                    fill="white"
                                    fill-opacity="0.58"
                                  />
                                </svg>
                              </div>
                              <img src={tlist.imgURL} alt={`edgeq-${index}`} />
                            </div>
                            <div className="video-slide-content" key={index}>
                              <h3>{tlist.title}</h3>

                              <p>{tlist.details}</p>
                            </div>
                          </Col>
                        )
                      })}
                    </Row>
                  </TabPanel>

                  <TabPanel>
                    <Row>
                      {fundamental5GList.map((tlist, index) => {
                        return (
                          <Col md={6}>
                            <div className="img__Slide__boxs" key={index}>
                              <div
                                className="slideOverlays"
                                onClick={() =>
                                  location.pathname !== "/"
                                    ? medialightboxOpen(index)
                                    : lightboxOpen(index)
                                }
                              >
                                {" "}
                                <svg
                                  width="44"
                                  height="46"
                                  viewBox="0 0 44 46"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M22 0.65625C17.6612 0.65625 13.4198 1.96669 9.81219 4.42185C6.20458 6.87701 3.3928 10.3666 1.7324 14.4494C0.0720052 18.5322 -0.362431 23.0248 0.484033 27.3591C1.3305 31.6933 3.41984 35.6746 6.48786 38.7994C9.55587 41.9243 13.4648 44.0523 17.7202 44.9144C21.9757 45.7766 26.3866 45.3341 30.3951 43.6429C34.4037 41.9518 37.8299 39.0879 40.2404 35.4135C42.6509 31.7391 43.9375 27.4192 43.9375 23C43.9314 17.076 41.6181 11.3964 37.5054 7.20749C33.3926 3.01858 27.8163 0.662506 22 0.65625ZM22 41.9063C18.3287 41.9063 14.7398 40.7974 11.6872 38.72C8.63465 36.6425 6.25545 33.6898 4.8505 30.2351C3.44554 26.7804 3.07794 22.979 3.79418 19.3116C4.51042 15.6441 6.27833 12.2754 8.87434 9.63126C11.4704 6.98718 14.7779 5.18653 18.3786 4.45703C21.9794 3.72753 25.7117 4.10193 29.1036 5.5329C32.4954 6.96387 35.3945 9.38713 37.4342 12.4963C39.4738 15.6054 40.5625 19.2607 40.5625 23C40.5569 28.0125 38.5994 32.8181 35.1195 36.3625C31.6396 39.9068 26.9214 41.9006 22 41.9063ZM29.6866 21.5691L19.5616 14.6941C19.3074 14.5214 19.0119 14.4222 18.7067 14.4071C18.4014 14.3921 18.0979 14.4617 17.8285 14.6086C17.5591 14.7555 17.334 14.9742 17.177 15.2412C17.0201 15.5083 16.9373 15.8138 16.9375 16.125V29.875C16.9373 30.1862 17.0201 30.4917 17.177 30.7588C17.334 31.0258 17.5591 31.2445 17.8285 31.3914C18.0979 31.5383 18.4014 31.6079 18.7067 31.5929C19.0119 31.5778 19.3074 31.4786 19.5616 31.3059L29.6866 24.4309C29.918 24.274 30.1079 24.0612 30.2392 23.8116C30.3705 23.562 30.4392 23.2832 30.4392 23C30.4392 22.7168 30.3705 22.438 30.2392 22.1884C30.1079 21.9388 29.918 21.726 29.6866 21.5691ZM20.3125 26.6631V19.3477L25.7083 23L20.3125 26.6631Z"
                                    fill="white"
                                    fill-opacity="0.58"
                                  />
                                </svg>
                              </div>
                              <img src={tlist.imgURL} alt={`edgeq-${index}`} />
                            </div>
                            <div className="video-slide-content" key={index}>
                              <h3>{tlist.title}</h3>

                              <p>{tlist.details}</p>
                            </div>
                          </Col>
                        )
                      })}
                    </Row>
                  </TabPanel>

                  <TabPanel>
                    <Row>
                      {demo5GList.map((tlist, index) => {
                        return (
                          <Col md={6}>
                            <div className="img__Slide__boxs" key={index}>
                              <div
                                className="slideOverlays"
                                onClick={() =>
                                  location.pathname !== "/"
                                    ? medialightboxOpen(index)
                                    : lightboxOpen(index)
                                }
                              >
                                {" "}
                                <svg
                                  width="44"
                                  height="46"
                                  viewBox="0 0 44 46"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M22 0.65625C17.6612 0.65625 13.4198 1.96669 9.81219 4.42185C6.20458 6.87701 3.3928 10.3666 1.7324 14.4494C0.0720052 18.5322 -0.362431 23.0248 0.484033 27.3591C1.3305 31.6933 3.41984 35.6746 6.48786 38.7994C9.55587 41.9243 13.4648 44.0523 17.7202 44.9144C21.9757 45.7766 26.3866 45.3341 30.3951 43.6429C34.4037 41.9518 37.8299 39.0879 40.2404 35.4135C42.6509 31.7391 43.9375 27.4192 43.9375 23C43.9314 17.076 41.6181 11.3964 37.5054 7.20749C33.3926 3.01858 27.8163 0.662506 22 0.65625ZM22 41.9063C18.3287 41.9063 14.7398 40.7974 11.6872 38.72C8.63465 36.6425 6.25545 33.6898 4.8505 30.2351C3.44554 26.7804 3.07794 22.979 3.79418 19.3116C4.51042 15.6441 6.27833 12.2754 8.87434 9.63126C11.4704 6.98718 14.7779 5.18653 18.3786 4.45703C21.9794 3.72753 25.7117 4.10193 29.1036 5.5329C32.4954 6.96387 35.3945 9.38713 37.4342 12.4963C39.4738 15.6054 40.5625 19.2607 40.5625 23C40.5569 28.0125 38.5994 32.8181 35.1195 36.3625C31.6396 39.9068 26.9214 41.9006 22 41.9063ZM29.6866 21.5691L19.5616 14.6941C19.3074 14.5214 19.0119 14.4222 18.7067 14.4071C18.4014 14.3921 18.0979 14.4617 17.8285 14.6086C17.5591 14.7555 17.334 14.9742 17.177 15.2412C17.0201 15.5083 16.9373 15.8138 16.9375 16.125V29.875C16.9373 30.1862 17.0201 30.4917 17.177 30.7588C17.334 31.0258 17.5591 31.2445 17.8285 31.3914C18.0979 31.5383 18.4014 31.6079 18.7067 31.5929C19.0119 31.5778 19.3074 31.4786 19.5616 31.3059L29.6866 24.4309C29.918 24.274 30.1079 24.0612 30.2392 23.8116C30.3705 23.562 30.4392 23.2832 30.4392 23C30.4392 22.7168 30.3705 22.438 30.2392 22.1884C30.1079 21.9388 29.918 21.726 29.6866 21.5691ZM20.3125 26.6631V19.3477L25.7083 23L20.3125 26.6631Z"
                                    fill="white"
                                    fill-opacity="0.58"
                                  />
                                </svg>
                              </div>
                              <img src={tlist.imgURL} alt={`edgeq-${index}`} />
                            </div>
                            <div className="video-slide-content" key={index}>
                              <h3>{tlist.title}</h3>

                              <p>{tlist.details}</p>
                            </div>
                          </Col>
                        )
                      })}
                    </Row>
                  </TabPanel>
                </div>
              
              </Tabs>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
export default Video
